.table {
    border-collapse: collapse;
    width: 976px;
}

.table-header {
    font-size: 11px;
}

.table-header-name {
    padding-left: 20px;
}

.table-body {
    font-size: 15px;
}

.table-entry {
    border: 1px solid var(--lightGrayishBlue);
}

.table-body-name {
    width: 300px;
    height: 50px;
    padding-left: 20px;
}

.table-body-state {
    text-align: center;
    width: 100px;
}

.pending-state {
    background-color: var(--lightBlue);
    position: relative;
    border-radius: 16px;
    width: 77px;
    padding: 8px;
}

.table-body-email {
    width: 350px;
    padding-right: 20px;
}

.table-body-role {
    width: 142px;
}

.table-delete-item {
    text-align: right;
    padding-right: 20px;
    padding-left: 20px;
}

.delete-item {
    cursor: pointer;
}

.edit-item {
    cursor: pointer;
}

.single-account-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    top: 15px;
}

.single-account-header {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.header-button {
    cursor: pointer;
    width: 24px;
    height: 24px;
}

.single-account-name {
    font-size: 24px;
    margin-left: 20px;
    flex-grow: 1;
    font-weight: bold;
}

.box-details {
    width: 142px;
    background-color: white;
    border-radius: 4px;
    position: fixed;
    right: 70px;
    top: 150px;
}

.details-button {
    width: 100%;
}

.form-wrapper {
    display: flex;
    flex-direction: row;
    padding-right: 20px;
    gap: 20px;
}

.outer-invite-box {
    width: 100%;
    max-width: 976px;
    background-color: var(--lightGrayishBlue3);
    margin-top: 20px;
}

.inner-invite-box {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}

.invite-textfield {
    width: 100%;
    background-color: white;
}

.select-role {
    width: 100%;
}

.select-role-item {
    font-size: 16px;
    height: 30px;
    align-self: center;
}

.send-invite-button {
    width: 130px;
    height: 39px;
}

.role-title {
    font-weight: bold;
    font-size: 21px;
}

.invite-item-height {
    height: 44px;
    flex: 1;
}

.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input.Mui-disabled {
    cursor: pointer !important;
}
.container {
    text-align: center;
}
.toggle-switch {
    position: relative;
    width: 75px;
    display: inline-block;
    text-align: left;
    top: 8px;
    left: 12px;
}
.checkbox {
    display: none;
}
.label {
    display: block;
    overflow: hidden;
    height: 20px;
    width: 50px;
    cursor: pointer;
    border: 0 solid #bbb;
    border-radius: 20px;
}
.inner {
    display: block;
    width: 200%;
    margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}
.inner:before,
.inner:after {
    float: left;
    width: 50%;
    height: 36px;
    padding: 0;
    line-height: 36px;
    color: #fff;
    font-weight: bold;
    box-sizing: border-box;
}
.inner:before {
    content: "";
    padding-left: 10px;
    background-color: #0000FF;
    color: #fff;
}
.inner:after {
    content: "";
    padding-right: 10px;
    background-color: #bbb;
    color: #fff;
    text-align: right;
}
.switch {
    display: block;
    width: 20px;
    margin: 0px;
    background: #fff;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 56px;
    border: 0 solid #bbb;
    border-radius: 20px;
    transition: all 0.3s ease-in 0s;
}
.checkbox:checked + .label .inner {
    margin-left: 0;
}
.checkbox:checked + .label .switch {
    right: 20px;
}

.table-responsive {
    overflow: auto;
}